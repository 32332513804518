//@import "variables";
//$primary: #1e596c;
$blue: #39a9ce;
$dark: #252b34;
$orange: #f66951;
$warning: #f66951;
$text-muted: #4c5359;
//$gray-600: #6c757d;
$primary: #52b4d4;

// Configuration
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/utilities";

// Layout & components
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/carousel";

// Helpers
@import "node_modules/bootstrap/scss/helpers";

// Utilities
@import "node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack
